import $ from 'jquery';
import Swiper from 'swiper';
import { Navigation, Thumbs, } from 'swiper/modules';

import 'swiper/swiper.scss';  
//import 'swiper/modules/pagination.scss'; 
import 'swiper/modules/navigation.scss'; 

import './../../scss/blocks/block-branches.scss';

$(document).ready(function() {

    var slidesnav = document.getElementsByClassName("branches-nav-swiper-wrap");
    var swipernavarray = [];
    for (var i = 0; i < slidesnav.length; i++) {
      //Distribute(slides.item(i));
      var branchesnavswiper = new Swiper(slidesnav.item(i).getElementsByClassName('branches-swiper-nav').item(0), {
        spaceBetween: 16,
        loop: false,
        slidesPerView: 1,
        breakpoints: {
            "768": {
              slidesPerView: "auto",
            }
          },
      }); 
      swipernavarray[i] = branchesnavswiper;
    }
    

    var slides = document.getElementsByClassName("branches-slider-wrap");

    for (var i = 0; i < slides.length; i++) {
      //Distribute(slides.item(i));
      var branchesswiper = new Swiper(slides.item(i).getElementsByClassName('branches-swiper').item(0), {
        modules: [Navigation, Thumbs],
        spaceBetween: 16,
        loop: false,
        slidesPerView: 1,
        navigation: {
          nextEl: slides.item(i).getElementsByClassName('branches-swiper-button-next').item(0),
          prevEl: slides.item(i).getElementsByClassName('branches-swiper-button-prev').item(0),
        },
        thumbs: {
            swiper: swipernavarray[i],
          },
      });  
    }
  

})